const iEUtils = {
    isIE() {
        if (!!window.ActiveXObject || "ActiveXObject" in window){
            return true;
        }else{
            return false ;
        }
    },
    //获取ie 版本
    getIEVersion() {
        if (document.all && !window.XMLHttpRequest) return 6;
        if (document.all && !document.querySelector) return 7;
        if (document.all && !document.addEventListener) return 8;
        if (document.all && !window.atob) return 9;
        if (document.all && document.addEventListener && window.atob) return 10;
        return 11;
    },
    //是否适配 (适配非IE浏览器 且 ie >= 10)
    hasAdapter(){
        let ieVersion = iEUtils.getIEVersion();
        let isIE = iEUtils.isIE();
        return !isIE || (isIE && ieVersion >= 10);
    }
}
export default iEUtils;